

.container {
    width: 94%;
    margin-right: 3%;
    margin-left: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.filterCard {
    padding: 2rem;
    margin: 1rem;
    border-radius: 3rem;
    border:1px solid var(--container-border);
    background-color: var(--tatami);
    align-items: left;
    justify-content:left;
}


.headerText {
  font-family: Inter, Georgia, 'Times New Roman', Times, serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.5;
  
}

.subTitle {
  font-family: Inter, Georgia, 'Times New Roman', Times, serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  
}

.filterContainer {
    width: 100%;
    background-color:var(--lightpeach);
    border-radius: 3rem;
    height: 100%;
    justify-items: center;
    padding: 1rem;
    align-items: center;
    height: 100%;
    box-shadow: var(--container-shadow) 0 1px 0 inset,
    rgba(46, 54, 80, 0.228) 3px 9px 1px;
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 1rem;
    border: 5px;
    border-color:var(--container-border);
    border-style: solid;
}

.tableCard {
    width: 100%;
    border-radius: 1rem;
}

.questionActionsContainer {
    width: 100%;
    background-color: var(--lightpeach);
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border: 1px;
    border-color:var(--slate);
    border-style: solid;
}

.paginationContainer {
    margin: 2%;
    background-color: var(--tatami);
    border-radius: 1rem;
    justify-content: center;
    display: inline-flex;
}

.dropdownContainer {
    border-radius: 3rem;
    font-family: Inter, sans-serif;
    background-color: var(--darkpeach);
    border: 1px solid var(--slate);
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.5rem;
    display: flex;
    justify-items: center;
    width: 100%;
    padding:0.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    
  }

.addQuestionsButton {
    appearance: button;
    background-color: var(--mint);
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
    );
    border-radius: 1rem;
    box-shadow: var(--button-shadow) 0 1px 0 inset,
      rgba(46, 54, 80, 0.075) 0 1px 1px;
    box-sizing: border-box;
    color: #f1e7e7;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

.deleteQuestionsButton {
    appearance: button;
    background-color: var(--peach);
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
    );
    border-radius: 1rem;
    box-shadow: var(--button-shadow) 0 1px 0 inset,
      rgba(46, 54, 80, 0.075) 0 1px 1px;
    box-sizing: border-box;
    color: #fafafa;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}


.createQuestionButton {
    background-color: var(--mint);
    
    margin-top: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 1rem;
    /* padding: 0.3rem; */
    border-width: 3px;
    border-color: rgb(2, 39, 2);
    box-shadow: var(--button-shadow) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 3px 5px 1px;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, Georgia, 'Times New Roman', Times, serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}


.deleteButton {
    margin: 5%;
    margin-top: 0;
    width: 60%;
    display: flex;
    border-radius: 1rem;
    border-width: 3px;
    border-color: var(--warning-button-border);
    box-shadow: var(--button-shadow) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 3px 5px 1px;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

.createClassroomButton {
    margin: 5%;
    margin-top: 1rem;
    width: 60%;
    display: flex;
    border-radius: 1rem;
    border-width: 3px;
    border-color: rgb(2, 39, 2);
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    background-color: var(--mint);
}

.joinClassroomButton {
    background-color: var(--mint);
    margin: 5%;
    margin-top: 1rem;
    width: 60%;
    display: flex;
    border-radius: 1rem;
    border-width: 3px;
    border-color: rgb(2, 39, 2);
    box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 3px 5px 1px;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

.practiceButton {
    background-color: var(--mint);
    margin: 5%;
    margin-top: 1rem;
    width: 60%;
    display: flex;
    border-radius: 1rem;
    border-width: 3px;
    border-color: rgb(2, 39, 2);
    box-shadow: var(--button-shadow) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 3px 5px 1px;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }

.showFiltersButton {
  background-color: var(--peach);
    margin: 2%;
    max-width: 80%;
    display: flex;
    margin-top: 1rem;
    border-radius: 1rem;
    border-width: 3px;
    border-color: rgb(2, 39, 2);
    box-shadow: var(--button-shadow) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 3px 5px 1px;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    
}
  