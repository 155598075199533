.container {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dropdownContainer {
    border-radius: 1rem;
    box-shadow: rgba(126, 115, 115, 0.15) 0 1px 0 inset,
      rgba(46, 54, 80, 0.075) 0 1px 1px;
    font-family: Inter, sans-serif;
    background-color: var(--lightmint);
    border: 1px solid #dcdbec;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.5rem;
    display: flex;
    margin-bottom: 1rem;
    justify-items: center;
    width: 100%;
  }

.formContainer {
    
    width: 100%;
    background-color: var(--lightpeach);
    border-radius: 1rem;
    height: 100%;
    justify-items: center;
    padding: 1rem;
    align-items: center;
    height: 100%;
    box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.228) 3px 9px 1px;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 3rem;
    border: 5px;
    border-color:rgb(88, 247, 173);
    border-style: solid;
    
    }

.answersContainer { 
    
    margin: 1rem;
    background-color:var(--lightmint);
    border-radius:1rem;
    justify-items: center;
    padding: 1rem;
    align-items: center;
    height: 100%;
    box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.228) 3px 9px 1px;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 3rem;
    border: 5px;
    border-color:rgb(88, 247, 173);
    border-style: solid;
}

.answerDropdownContainer {
    border-radius: 1rem;
    box-shadow: rgba(126, 115, 115, 0.15) 0 1px 0 inset,
      rgba(46, 54, 80, 0.075) 0 1px 1px;
    font-family: Inter, sans-serif;
    background-color: rgb(226, 203, 230);
    border: 1px solid #dcdbec;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.5rem;
    display: flex;
    margin-bottom: 1rem;
    justify-items: center;
    width: 100%;
}

.addAnswerButton {
    background-color: rgb(55, 155, 55);
    margin: 5%;
    margin-top: 1rem;
    display: flex;
    border-radius: 1rem;
    border-width: 3px;
    border-color: rgb(2, 39, 2);
    box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 3px 5px 1px;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}


.removeAnswerButton {
    background-color: var(--peach);
    margin: 5%;
    margin-top: 1rem;
    display: flex;
    border-radius: 1rem;
    border-width: 3px;
    border-color: rgb(2, 39, 2);
    box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 3px 5px 1px;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

.updateQuestionButton {
    background-color: rgb(49, 38, 36);
    margin: 5%;
    display: flex;
    border-radius: 1rem;
    border-width: 3px;
    border-color: rgb(2, 39, 2);
    box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 3px 5px 1px;
    color: whitesmoke;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-transform: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}