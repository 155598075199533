
.paragraphCard {
    
    overflow: scroll;
    margin: 5%;
    padding: 1rem;
    border-radius: 1rem;
    border-width: 0px;
    border-color: 2px solid black;
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 10px 3px;
}
.paragraphCard:hover {
    border-color: black;
    transform: scale(1.05);
}

.img {
    border: 0px solid rgb(74, 68, 68);
    min-height: 100px;
    max-width: 100%;
    max-height: 300px;
    padding: 1rem;
    margin: 1rem;
    border-radius: 2rem;
  }

.chartContainer  {
    border-radius: 2rem;
    /* display: flex; */
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    color: grey;
    border-width: 15px;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid var(--mint);
    background-color: rgb(41, 40, 39);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.calendarContainer  {
    border-radius: 2rem;
    /* display: flex; */
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    color: grey;
    border-width: 15px;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid var(--mint);
    background-color: rgb(41, 40, 39);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.circlePackContainer  {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    color: rgb(101, 53, 53);
    border-width: 15px;
    margin-top: 1rem;
    padding: 3rem;
    border: 1px solid ;
    background-color: rgb(41, 40, 39);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.bubbleChartContainer  {
    border-radius: 2rem;
    display: flex;
    justify-content:center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    color: rgb(101, 53, 53);
    border-width: 15px;
    margin-top: 1rem;
    padding: 3rem;
    border: 1px solid ;
    background-color: rgb(41, 40, 39);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.zoomableIcicleContainer  {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    color: rgb(101, 53, 53);
    border-width: 15px;
    margin-top: 1rem;
    padding: 3rem;
    border: 1px solid ;
    background-color: rgb(41, 40, 39);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.tooltip {
    
        position: absolute;
        text-align: center;
        padding: 2px;
        font: 20px sans-serif;
        background: lightsteelblue;
        border: 0px;
        border-radius: 8px;
        pointer-events: none;
        opacity: 0;
      
}
.zoomableSunburstContainer  {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    color: rgb(101, 53, 53);
    border-width: 15px;
    margin-top: 1rem;
    padding: 3rem;
    border: 1px solid ;
    background-color: rgb(41, 40, 39);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.characterHeatmap  {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    color: rgb(252, 217, 217);
    border-width: 15px;
    margin-top: 1rem;
    padding: 3rem;
    border: 1px solid ;
    background-color: rgb(41, 40, 39);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}


.timeNetworkContainer  {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    max-height: 500px;
    border-width: 0px;
    border: 0px solid ;
    background-color: rgba(41, 40, 39, 0);
    
}

.networkContainer  {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    color: rgb(101, 53, 53);
    border-width: 15px;
    margin-top: 1rem;
    padding: 3rem;
    border: 1px solid ;
    background-color: rgb(41, 40, 39);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}
.treemapContainer  {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: grey;
    border-width: 15px;
    margin-top: 1rem;
    max-height: 600px;
    border: 1px solid var(--mint);
    background-color: rgb(41, 40, 39);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}
.donutContainer {
    align-items: center;
    min-height: 16rem;
    
    margin: 1rem;
    padding-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    border-radius: 1rem;
    border: 1px solid var(--mint);
    background-color: rgb(238, 236, 233);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.svgContainer {
    display: inline-block;
    position: relative;
    
    width: 100%;
    padding-bottom: 100%; /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
  }
.svgContentResponsive {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
  }

.countContainer {
    align-items: center;
    min-height: 16rem;
    margin: 1rem;
    padding: 3px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    border-radius: 1rem;
    border: 1px solid var(--mint);
    background-color: rgb(238, 236, 233);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.tableContainer {
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: grey;
    border-width: 15px;
    padding: 5%;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid var(--mint);
    background-color: rgb(238, 236, 233);
    box-shadow: rgba(255, 255, 255, 0.389) 0 1px 0 inset,
    rgba(46, 54, 80, 0.277) 2px 5px 3px;
}

.trickyWordsHeader {
    font-family: Inter, Georgia;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.5;
}
.trickyWordText {
    font-family: Inter, Georgia;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
}

.fluencyText {
    color: var(--mint);
    font-family: Inter, Georgia;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;

}
.trickyWordContainer {
    background-color: var(--peach);
    padding: 2%;
    margin:2%;
    min-width: 96%;
    border-radius: 2rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    
}